const colors = {
    appBarBackground: '#F8F8F8',
    lightShadow: '#F0F0F0',
    black: '#000000',
    button: '#001E64',
    darkGray: '#222222',
    focusedField: '#913DD8',
    main: '#001E64',
    lightMain: '#9BDCF0',
    lighterMain: '#9BDCF0',
    lightBorderGray: '#E1E1E1',
    lightGray: '#F5F9FB',
    lightDarkerGray: '#BFBFBF',
    darkIcon: '#263238',
    labelColor: '#929292',
    lightWarning: 'rgba(255, 0, 0, 0.20)',
    midGray: '#DEEAF1',
    midDarkerGray: '#737373',
    warning: '#FF0000',
    white: '#FFFFFF',
}

export default colors
